// const XlsxPopulate = require('xlsx-populate');
import XlsxPopulate from "xlsx-populate"

let Xlsx = {};
Xlsx.style = {
    header: {
        bold: true, verticalAlignment: 'center', horizontalAlignment: "center", wrapText: true,fill:"f8f7f7",fontColor:"4b5055",borderColor:"c2bfbf",borderStyle:"thin"

    },
body: { numberFormat: "* #,##0" },
};
Xlsx.IntFileXlsx = () => {
    return XlsxPopulate.fromBlankAsync();
}
Xlsx.SaveFile = (workbook, config) => {
    return workbook.outputAsync().then(function (blob) {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, `${config.nameFile}.xlsx`);
        } else {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.href = url;
            a.download = `${config.nameFile}.xlsx`;
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        }
    });
}
// Xlsx.WriteFile = (config, body) => {
//     XlsxPopulate.fromFileAsync(`./${config.nameFile}.xlsx`)
//         .then(workbook => {
//             // Modify the workbook.
//             const value = workbook.sheet("Sheet1").cell("A1").value("hello test");

//             // Log the value.
//             console.log(value);
//         });
// }

export default Xlsx;