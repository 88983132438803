import ApiService from '../service/ApiService';

let moduleApi = {};
let moduleName = "prefill";

moduleApi.findById = (id) => {
  let api = {
    module: moduleName,
    type: "findById",
    value: { id: id }
  };
  return ApiService(api);
};

moduleApi.create = (dataForm) => {
  let api = {
    module: moduleName,
    type: "create",
    value: dataForm,
    message: {
      success: "hidden",
      error: ""
    },
  };
  return ApiService(api);
};


export default moduleApi;
